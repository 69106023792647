<template>
  <div id="overlay" v-if="overlay"></div>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Cadena de Comercialización
    </template>
    <template v-slot:subtitle>
      Añadir punto de venta
    </template>
    <template #content>
      <div class="filters">
        <div class="field">
          <!-- Comercializador -->
          <Dropdown v-model="selectedComercializador" :options="comercializadores"
                    optionLabel="tcomercializador" optionValue="id_comercializador"
                    placeholder="Seleccionar Bloque" class="filterItem" :loading="loadingComercializador"
                    :showClear="true"
                    v-if="Number(identity.roleId) <= 1"
                    style="width: 100%;"
                    :filter="true"/>
          <br>
          <!-- Banca -->
          <Dropdown v-model="selectedBanca" :options="bancas"
                    optionLabel="tbanca" optionValue="id_banca"
                    placeholder="Seleccionar Banca" class="filterItem" :loading="loadingBanca"
                    :showClear="true"
                    :onClick="refreshBanca"
                    v-if="Number(identity.roleId) <= 2 && selectedComercializador"
                    style="width: 100%"
                    :filter="true"/>
          <br>
          <!-- Distribuidor -->
          <Dropdown v-model="selectedDistribuidor" :options="distribuidores"
                    optionLabel="tdistribuidor" optionValue="id_distribuidor"
                    placeholder="Seleccionar Distribuidor" class="filterItem" :loading="loadingDistribuidor"
                    :showClear="true"
                    v-if="Number(identity.roleId) <= 3 && selectedBanca"
                    style="width: 100%"
                    :onClick="refreshDistribuidor"
                    :filter="true"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-between">
        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="nextDisabled()"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {useVuelidate} from "@vuelidate/core";

export default {
  name: "Step2",
  mixins: [generalMixin],
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.mainService = new MainService();

    this.theDate = this.getYesterdayString();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";

    this.identity = this.getIdentity();

    this.comercializadores = this.refreshComercializador();
    this.bancas = this.refreshBanca();
    this.distribuidores = this.refreshDistribuidor();

    this.selectedComercializador = this.$store.getters.getAgency.id_comercializador;
    this.selectedBanca = this.$store.getters.getAgency.id_banca;
    this.selectedDistribuidor = this.$store.getters.getAgency.id_distribuidor;
  },
  mounted() {
    // It's a block
    if (Number(this.identity.roleId) <= 2) {
      this.selectedComercializador = this.identity.entityId;
    }
    // It's a banking
    if (Number(this.identity.roleId) <= 3) {
      this.selectedBanca = this.identity.entityId;
    }

  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/agencias/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/agencias/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/agencias/agregar-3'
        },
      ],
      selectedComercializador: null,
      selectedBanca: null,
      selectedDistribuidor: null,
      selectedAgencia: null,

      comercializadores: null,
      bancas: null,
      distribuidores: null,
      agencias: null,

      loadingComercializador: false,
      loadingBanca: false,
      loadingDistribuidor: false,
      loadingAgencia: false,

      pageIndex: 0,
      identity: null,
      params: null,

      enabled: false,
      overlay: false,
    }
  },
  methods: {
    async refreshComercializador() {
      this.overlay = true;
      this.loading = true;
      this.selectedComercializador = null;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'agencies',
        hide_external: true,
      });
      this.loading = false;
      this.overlay = false;
    },
    async refreshBanca() {
      this.overlay = true;
      this.loading = true;
      this.selectedBanca = null;
      this.bancas = await this.mainService.getBanca({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'agencies',
        hide_external: true,
      });
      this.loading = false;
      this.overlay = false;
    },
    async refreshDistribuidor() {
      this.overlay = true;
      this.loading = true;
      this.selectedDistribuidor = null;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        page: 'agencies',
        hide_external: true,
      });
      this.loading = false;
      this.overlay = false;
    },
    async refreshAgencia() {
      this.overlay = true;
      this.loading = true;
      this.selectedAgencia = null;
      this.agencias = await this.mainService.getAgencia({
        fecha: this.theDate,
        identity: this.identity,
        comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        id_distribuidor: this.selectedDistribuidor,
        page: 'agencies',
        hide_external: true,
      });
      this.loading = false;
      this.overlay = false;
    },
    nextPage() {
      this.$store.commit('setAgency', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        id_distribuidor: this.selectedDistribuidor,
        agencyName: this.$store.getters.getAgency.agencyName,
        id_estado: this.$store.getters.getAgency.id_estado,
        id_municipio: this.$store.getters.getAgency.id_municipio,
      });
      this.$router.push({name: 'agencias/agregar-3',});
    },
    prevPage() {
      this.$store.commit('setAgency', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        id_distribuidor: this.selectedDistribuidor,
        agencyName: this.$store.getters.getAgency.agencyName,
        id_estado: this.$store.getters.getAgency.id_estado,
        id_municipio: this.$store.getters.getAgency.id_municipio,
      });
      this.$router.push('/agencias/agregar-1');
    },
    nextDisabled() {
      let response = !this.selectedDistribuidor;
      if (this.$store.getters.getProfile.role_id === 4) {
        response = false;
      }
      return response;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 20px 0 5px 0;
}

</style>